import { RichText } from "prismic-reactjs"
import React from "react"
import htmlSerializer from "./HtmlSerializer"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import { graphql, StaticQuery } from "gatsby"
export default function TextRenderer({ raw }) {
  return (
    <StaticQuery
      query={graphql`
        {
          config: prismicSiteConfigurations {
            data {
              email
            }
          }
        }
      `}
      render={({ config }) => {
        return (
          <div
            className="prose lg:prose-xl"
            css={css`
              .project-page & {
                p,
                strong,
                li,
                ul {
                  font-family: "Georgia";
                  line-height: 1.6;
                }
              }

              h1,
              h2,
              h3 {
                font-weight: bold;
                color: #222020;
                strong {
                  font-weight: bold;
                  color: #222020;
                }
              }
              .divider {
                margin: 3rem auto !important;
                @media (min-width: 1024px) {
                  margin: 4rem auto !important;
                }
              }
              .underlined-link {
                ${tw`inline-block border-b-2 border-dashed border-text`}

                @media (min-width: 768px) {
                  &:hover {
                    background-image: linear-gradient(
                      to right,
                      #0069ff -13%,
                      #2fb3ff 125%
                    );
                    cursor: pointer;
                    background-clip: text;
                    -webkit-background-clip: text;
                    color: transparent;
                    border-color: #0069ff;
                  }
                }
              }

              .primary-button {
                min-width: 96px;
                margin-right: 38px;
                .text-center & {
                  margin-right: 13px;
                  margin-left: 13px;
                  @media (min-width: 1024px) {
                    margin-right: 20px;
                    margin-left: 20px;
                  }
                }
                ${tw`inline-flex font-bold items-center justify-center  rounded text-white phone:px-3 px-4 py-2 mr-8  phone:mb-6  `}
                background-image: linear-gradient(to right, #0069ff -13%, #2fb3ff 125%);
                &:hover {
                  background-image: linear-gradient(to right, #0069ff, #0069ff);
                }
              }
              .secondary-button {
                ${tw`inline-block border-b-2 border-dashed border-text phone:mb-6`}
                margin-right: 38px;
                .text-center & {
                  margin-right: 13px;
                  margin-left: 13px;
                  @media (min-width: 1024px) {
                    margin-right: 20px;
                    margin-left: 20px;
                  }
                }
                @media (min-width: 768px) {
                  &:hover {
                    background-image: linear-gradient(
                      to right,
                      #0069ff -13%,
                      #2fb3ff 125%
                    );
                    cursor: pointer;
                    background-clip: text;
                    -webkit-background-clip: text;
                    color: transparent;
                    ${tw`md:border-primary-DEFAULT`}
                  }
                }
              }

              .text-primary * {
                color: #0069ff !important;
              }
              .text-charcoal* {
                color: #222020 !important;
              }
              .text-slate * {
                color: #596379 !important;
              }
              .text-dusk * {
                color: #8c8c8c !important;
              }
              ol {
                padding-left: 1em;
                list-style-type: decimal;
                list-style-position: outside;
                li {
                  padding-left: 0.75rem;
                }
                > li::before {
                  display: none;
                }
              }

              .link {
                border-bottom: 2px dashed transparent;
                &:hover {
                  border-bottom: 2px dashed #8c8c8c;
                }
              }

              ul {
                > li::before {
                  background-color: #5a6278;
                }
              }

              .list & {
                ul {
                  li {
                    padding-left: 0;
                    margin-bottom: 8px;
                  }
                  > li::before {
                    display: none;
                  }
                  a {
                    border-bottom: 2px dashed transparent;
                    &:hover {
                      border-bottom: 2px dashed #8c8c8c;
                    }
                  }
                }
              }
              .columns & {
                ul {
                  li {
                    padding-left: 0;
                    margin-top: 0;
                    margin-bottom: 4px;
                    strong {
                      @media (min-width: 1024px) {
                        display: inline-block;
                        margin-bottom: 12px;
                        margin-top: 0;
                      }
                    }
                  }
                  > li::before {
                    display: none;
                  }
                }
                a {
                  border-bottom: 2px dashed transparent;
                  &:hover {
                    border-bottom: 2px dashed #8c8c8c;
                  }
                }
              }
              .oembed {
                ${tw`w-full relative`}
                iframe {
                  ${tw`w-full h-full absolute top-0 left-0`}
                }
              }
            `}
          >
            <RichText
              render={raw}
              htmlSerializer={htmlSerializer({ email: config.data.email })}
            />
          </div>
        )
      }}
    ></StaticQuery>
  )
}
