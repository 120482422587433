import React from "react"

export default function MailIcons({ ...rest }) {
  return (
    <svg version="1.1" viewBox="0 0 14 14" width="1em" height="1em" {...rest}>
      <path
        d="M13.0606,2.05225h-12.1212c-0.516663,0 -0.939392,0.42273 -0.939392,0.939392v8.01672c0,0.516663 0.422729,0.939392 0.939392,0.939392h5.35931h0.701294h6.06061c0.516663,0 0.939392,-0.42273 0.939392,-0.939392v-8.01672c0,-0.516662 -0.422729,-0.939392 -0.939392,-0.939392Zm-0.841004,7.64819c0.15918,0.141968 0.172058,0.391541 0.03009,0.55072c-0.077454,0.08606 -0.184998,0.129089 -0.292542,0.129089c-0.090332,0 -0.184998,-0.030151 -0.258118,-0.098999l-3.33441,-2.99017l-1.1272,0.873413c-0.07312,0.055908 -0.154907,0.081726 -0.240967,0.081726c-0.085999,0 -0.172058,-0.025818 -0.240906,-0.081726l-1.09283,-0.847595l-3.36877,2.96869c-0.07312,0.068848 -0.167786,0.098938 -0.258118,0.098938c-0.107544,0 -0.215149,-0.047302 -0.292542,-0.133362c-0.142029,-0.15918 -0.129089,-0.404419 0.038696,-0.546387l3.2569,-2.86975l-3.23968,-2.51685c-0.172119,-0.133423 -0.202209,-0.374328 -0.068847,-0.546447c0.133361,-0.172059 0.374328,-0.20221 0.546386,-0.068848l3.60974,2.8009l1.10999,0.864746l4.72833,-3.66992c0.167847,-0.133362 0.413086,-0.103271 0.546448,0.068848c0.133362,0.167786 0.103271,0.413025 -0.068848,0.546387l-3.2096,2.49109l3.22681,2.89551Z"
        fill="currentColor"
      />
    </svg>
  )
}
