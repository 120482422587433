import React from "react"
import Helmet from "react-helmet"
import get from "lodash.get"
import { graphql, StaticQuery } from "gatsby"
import has from "lodash.has"
export default function Seo({ data }) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={({ site }) => {
        const url =
          typeof window !== "undefined"
            ? window.location.href
            : get(site, "siteMetadata.siteUrl", "")
        const title = get(data, "metatitle")
        const description = get(data, "metadescription")
        const keywords = get(data, "metakeywords")
        const image = get(data, "metaimage.url")
        return (
          <>
            <Helmet>
              {url && <meta name="og:url" content={url} />}
              <meta name="lang" content="en"></meta>
              <meta name="og:type" content="article" />
              {title && <title>{title}</title>}
              {description && <meta name="description" content={description} />}
              {keywords && <meta name="keywords" content={keywords} />}
              {image && <meta name="image" content={image} />}
              {title && <meta property="og:title" content={title} />}
              {description && (
                <meta property="og:description" content={description} />
              )}
              {keywords && <meta property="og:keywords" content={keywords} />}
              {image && <meta property="og:image" content={image} />}
              {title && <meta name="twitter:title" content={title} />}
              {description && (
                <meta name="twitter:description" content={description} />
              )}
              {keywords && <meta name="twitter:keywords" content={keywords} />}
              {image && <meta name="twitter:image" content={image} />}
              <meta name="twitter:card" content="summary_large_image" />{" "}
              <meta name="twitter:creator" content={"@VikramAditya94"} />
            </Helmet>
          </>
        )
      }}
    />
  )
}
