import { graphql, Link, navigate, StaticQuery } from "gatsby"
import get from "lodash.get"
import React from "react"
import LinkResolver from "./LinkResolver"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import SocialLinks from "./SocialLinks"
export default function Footer({ fixedContainer }) {
  return (
    <StaticQuery
      query={graphql`
        {
          config: prismicSiteConfigurations {
            data {
              footer_links {
                label
                link {
                  uid
                  link_type
                  url
                  document {
                    ... on PrismicProject {
                      uid
                      type
                    }
                    ... on PrismicPage {
                      uid
                      type
                    }
                  }
                }
              }
              legal_text {
                html
              }
              text {
                html
              }
            }
          }
        }
      `}
      render={({ config }) => {
        const links = get(config, "data.footer_links")
        return (
          <div className="bg-black phone:pt-12 pt-24 pb-6  overflow-hidden">
            <div className="container prose-xl text-center">
              <div
                className="md:space-x-8 phone:flex flex-col items-center space-y-8  mb-10 text-dusk"
                css={css`
                  a {
                    display: inline-block;
                    border-bottom: 2px dashed #8c8c8c;
                    @media (min-width: 768px) {
                      &:hover {
                        background-image: linear-gradient(
                          to right,
                          #0069ff -13%,
                          #2fb3ff 125%
                        );
                        cursor: pointer;
                        background-clip: text;
                        -webkit-background-clip: text;
                        color: transparent;
                        border-color: #0069ff;
                      }
                    }
                  }
                `}
              >
                {links &&
                  links.map(({ link, label }, index) => {
                    return (
                      <LinkResolver
                        className="phone:text-base"
                        link={link}
                        key={index}
                      >
                        {label}
                      </LinkResolver>
                    )
                  })}
                <a
                  onClick={() => {
                    const el = document.getElementById("PageAnimation")
                    if (el && fixedContainer) {
                      el.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    } else {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }
                  }}
                  className="cursor-pointer phone:text-base"
                >
                  Go to Top
                </a>
              </div>
              <div
                className="text-white"
                dangerouslySetInnerHTML={{
                  __html: get(config, "data.text.html"),
                }}
              ></div>
              <div className=" mt-6 mb-20">
                <SocialLinks alignment="center" />
              </div>
              <div
                className="text-dusk text-sm"
                css={css`
                  @media (min-width: 768px) {
                    a:hover {
                      background-image: linear-gradient(
                        to right,
                        #0069ff -13%,
                        #2fb3ff 125%
                      );
                      background-clip: text;
                      -webkit-background-clip: text;
                      color: transparent;
                      border-color: #0069ff;
                    }
                  }
                  p {
                    ${tw`text-sm`}
                  }
                `}
                dangerouslySetInnerHTML={{
                  __html: get(config, "data.legal_text.html"),
                }}
              ></div>
            </div>
          </div>
        )
      }}
    ></StaticQuery>
  )
}
