import React from "react"

export default function Menu({ ...props }) {
  return (
    <svg version="1.1" viewBox="0 0 14 14" {...props}>
      {" "}
      <g fill="currentColor">
        <path d="M13.3942,7.6001h-12.7884c-0.334461,0 -0.605779,-0.268555 -0.605779,-0.600098c0,-0.331543 0.271318,-0.600098 0.605779,-0.600098h12.7884c0.334461,0 0.605778,0.268555 0.605778,0.600098c0,0.331543 -0.271317,0.600098 -0.605779,0.600098Z" />
        <path d="M13.3942,2.6001h-12.7884c-0.334461,0 -0.605779,-0.268555 -0.605779,-0.600098c0,-0.331543 0.271318,-0.600098 0.605779,-0.600098h12.7884c0.334461,0 0.605778,0.268555 0.605778,0.600098c0,0.331543 -0.271317,0.600098 -0.605779,0.600098Z" />
        <path d="M13.3942,12.6001h-12.7884c-0.334461,0 -0.605779,-0.268555 -0.605779,-0.600098c0,-0.331543 0.271318,-0.600098 0.605779,-0.600098h12.7884c0.334461,0 0.605778,0.268555 0.605778,0.600098c0,0.331543 -0.271317,0.600098 -0.605779,0.600098Z" />
      </g>
    </svg>
  )
}
