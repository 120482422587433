import { graphql, Link, StaticQuery, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React, { useMemo, useState } from "react"
import { css } from "@emotion/core"
import tw from "tailwind.macro"
import cn from "classnames"
import get from "lodash.get"
import { AnimateSharedLayout, motion } from "framer-motion"
import LinkResolver from "./LinkResolver"
import Menu from "./icons/Menu"
import CloseIcon from "./CloseIcon"

export default function Header({ hideHeader }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        {
          config: prismicSiteConfigurations {
            data {
              logo {
                url
                dimensions {
                  height
                  width
                }
              }
              header_links {
                label
                link {
                  uid
                  link_type
                  target
                  url
                  document {
                    ... on PrismicProject {
                      uid
                      type
                    }
                    ... on PrismicPage {
                      uid
                      type
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ config }) => {
        return (
          <>
            {isOpen && <div className="h-24"></div>}
            <div
              className={cn("container   z-40 overflow-hidden", {
                "fixed top-0 w-full": isOpen,
                relative: !isOpen,
              })}
            >
              <div className="flex items-center justify-between h-24">
                <div className="relative z-40">
                  <div
                    className="relative"
                    css={css`
                      width: 58px;
                      height: 58px;
                    `}
                  >
                    <Link
                      activeClassName="active"
                      to="/"
                      className="inline-flex items-center justify-center rounded-full w-full h-full"
                    >
                      <Logo url={get(config, "data.logo.url")} />
                    </Link>
                    <Circle />
                  </div>
                </div>
                <div className="phone:hidden">
                  {" "}
                  <div className="flex items-center space-x-9">
                    {get(config, "data.header_links").map(
                      ({ label, link }, index) => {
                        return (
                          <LinkResolver
                            navlink
                            link={link}
                            key={index}
                            activeClassName="active"
                            className="block"
                            css={css`
                              transition: all 300ms ease-in-out;
                              &.active,
                              &:hover {
                                color: #1c6cfd;
                              }
                              &:after {
                                content: "";
                                display: block;
                                margin: 0 auto;
                                background-color: #1c6cfd;
                                height: 2px;
                                border-radius: 1px;
                                width: calc(100% - 15px);
                                transform-origin: center center;
                                transition: transform 300ms ease-in-out;
                                transform: scaleX(0);
                              }

                              &.active:after {
                                transform: scaleX(1);
                              }
                            `}
                          >
                            {label}
                          </LinkResolver>
                        )
                      }
                    )}
                  </div>
                </div>
                <MenuButton isOpen={isOpen} setIsOpen={setIsOpen} />
              </div>
            </div>
            <MobileMenu
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              links={get(config, "data.header_links")}
            />
          </>
        )
      }}
    ></StaticQuery>
  )
}

function Logo({ url }) {
  const [src] = useState(url)
  return (
    <img style={{ width: 48, height: 48 }} src={src} alt="Meet Vikram Logo" />
  )
}

function Circle() {
  const r = 58 / 2 - 2
  const p = Math.PI * 2 * r
  const D = r * 2 + 1
  return (
    <svg
      width="58px"
      height="58px"
      viewBox={`0 0 58 58`}
      className="absolute top-0 transform -rotate-90"
      css={css`
        pointer-events: none;
        circle {
          opacity: 0.2;

          transition: stroke-dashoffset 0.7s cubic-bezier(0.25, 0.1, 0.25, 1);
          stroke-dashoffset: ${p * 1.5}px;
        }
        .active + & circle {
          opacity: 1;
          stroke-dashoffset: 0px;
        }

        a:hover + & circle {
          stroke-dashoffset: 0px;
        }
      `}
    >
      <circle
        cx="50%"
        cy="50%"
        r={r}
        stroke={"#1c6cfd"}
        strokeWidth={2}
        strokeDasharray={`${4 * D} ${4 * D}`}
        fill="transparent"
      />
    </svg>
  )
}
function MobileMenu({ links, isOpen, setIsOpen }) {
  return (
    <motion.div
      className="fixed md:hidden w-full top-0 h-screen overflow-y-auto pt-40 bg-white z-30 flex flex-col items-center justify-start space-y-10"
      initial={{ opacity: 0, pointerEvents: "none" }}
      animate={
        isOpen
          ? {
              opacity: 1,
              pointerEvents: "all",
            }
          : { opacity: 0, pointerEvents: "none", transition: { delay: 0.2 } }
      }
    >
      {links &&
        links.map(({ label, link }, index) => {
          return (
            <motion.div
              initial={{ x: 30, opacity: 0 }}
              animate={
                isOpen
                  ? {
                      x: 0,
                      opacity: 1,
                      transition: { delay: 0.1 * index, ease: "easeOut" },
                    }
                  : {
                      x: 30,
                      opacity: 0,
                      transition: { delay: 0.1 * index, ease: "easeOut" },
                    }
              }
            >
              <LinkResolver
                navlink
                link={link}
                key={index}
                activeClassName="active"
                className="block text-2xl"
                onClick={() => {
                  setIsOpen(false)
                }}
                css={css`
                  transition: all 300ms ease-in-out;
                  &.active {
                    background-image: linear-gradient(
                      to right,
                      #0069ff -13%,
                      #2fb3ff 125%
                    );
                    font-weight: 600;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                `}
              >
                {label}
              </LinkResolver>
            </motion.div>
          )
        })}
    </motion.div>
  )
}
function MenuButton({ isOpen = true, setIsOpen }) {
  return (
    <div className="text-text md:hidden">
      {!isOpen && (
        <Menu
          width="24"
          height="24"
          onClick={() => {
            setIsOpen(true)
          }}
        />
      )}
      {isOpen && (
        <CloseIcon
          onClick={() => {
            setIsOpen(false)
          }}
          width="24"
          height="24"
        />
      )}
    </div>
  )
}
