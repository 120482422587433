import React from "react"

export default function CloseIcon(props) {
  return (
    <svg version="1.1" viewBox="0 0 14  14 " {...props}>
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
      >
        <path d="M2,2l10,10" />
        <path d="M12,2l-10,10" />
      </g>
    </svg>
  )
}
