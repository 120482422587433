import { graphql, StaticQuery } from "gatsby"
import get from "lodash.get"
import React from "react"
import { FaLinkedinIn, FaTwitter } from "react-icons/fa"
import MailIcons from "./MailIcons"

export default function SocialLinks({ alignment = "start", theme = "dusk" }) {
  return (
    <StaticQuery
      query={graphql`
        {
          config: prismicSiteConfigurations {
            data {
              email
              linkedin_link
              twitter_link
            }
          }
        }
      `}
      render={({ config }) => {
        return (
          <div
            className={`inline-flex items-center justify-${alignment} space-x-6 lg:space-x-8 `}
          >
            <a
              style={{ border: "none" }}
              target="_blank"
              href={get(config, "data.linkedin_link")}
              className={`social-link text-${theme} hover:text-primary`}
            >
              {" "}
              <FaLinkedinIn className="h-6 w-6" />
            </a>
            <a
              style={{ border: "none" }}
              target="_blank"
              href={get(config, "data.twitter_link")}
              className={`social-link text-${theme} hover:text-primary`}
            >
              <FaTwitter className="h-6 w-6" />
            </a>
            <a
              style={{ border: "none" }}
              target="_blank"
              href={`mailto:${get(config, "data.email")}`}
              className={`social-link text-${theme} hover:text-primary`}
            >
              <MailIcons className="h-6 w-6" />
            </a>
          </div>
        )
      }}
    ></StaticQuery>
  )
}
