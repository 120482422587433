import React from "react"
import get from "lodash/get"
import { css } from "@emotion/core"
import cn from "classnames"
export function Spacer({ slice, children }) {
  const desktop_space_bottom = get(slice, "primary.desktop_space_bottom")
  const desktop_space_top = get(slice, "primary.desktop_space_top")
  const mobile_space_bottom = get(slice, "primary.mobile_space_bottom")
  const mobile_space_top = get(slice, "primary.mobile_space_top")
  const background = get(slice, "primary.background")
  const bgvars = {
    Light: "bg-light",
    Primary: "bg-primary",
    Charcoal: "bg-charcoal",
    Dusk: "bg-dusk",
    Slate: "bg-slate",
  }
  return (
    <div
      className={cn(bgvars[background])}
      css={css`
        padding-top: ${mobile_space_top};
        padding-bottom: ${mobile_space_bottom};
        @media (min-width: 1024px) {
          padding-top: ${desktop_space_top};
          padding-bottom: ${desktop_space_bottom};
        }
      `}
    >
      {children}
    </div>
  )
}
