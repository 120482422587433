import React from "react"
import { Elements } from "prismic-reactjs"
import { FaArrowRight } from "react-icons/fa"
import MailIcons from "./MailIcons"
import SocialLinks from "./SocialLinks"
import { Link } from "gatsby"
import get from "lodash.get"
export default ({ email }) =>
  function (type, element, content, children, key) {
    switch (type) {
      case Elements.embed:
        console.log({ type, element, content, children, key })
        return (
          <div
            className="oembed"
            style={{
              width: "100%",
              paddingBottom: `${
                (element.oembed.height / element.oembed.width) * 100
              }%`,
            }}
            dangerouslySetInnerHTML={{ __html: element.oembed.html }}
          ></div>
        )
        break
      case Elements.paragraph:
        if (/\-\-\-/.test(element.text)) {
          return (
            <p
              key={key}
              className="mx-auto border-b-2 border-dashed border-feather phone:max-w-60  max-w-xs divider"
            ></p>
          )
        }
        break

      case Elements.hyperlink: {
        const type = get(element, "data.type")
        const slug = get(element, "data.slug")

        if (type == "page") {
          return (
            <Link to={`/${slug}/`} target={element.data.target}>
              {children}
            </Link>
          )
        } else if (type === "project") {
          return (
            <Link to={`/project/${slug}/`} target={element.data.target}>
              {children}
            </Link>
          )
        } else if (!type) {
          return (
            <a
              href={element.data.url}
              onClick={(e) => {
                if (element.data.url.startsWith("https://#")) {
                  e.preventDefault()
                  console.log(element.data.url)
                  const id = element.data.url.split("#")[1]
                  const el = id && document.getElementById(id)
                  if (el) {
                    el.scrollIntoView({ block: "center", behavior: "smooth" })
                  }
                }
              }}
              target={"_blank"}
            >
              {children}
            </a>
          )
        }
      }

      case Elements.label:
        switch (element.data.label) {
          case "spacer-8":
            return <div className="h-2"></div>
          case "spacer-16":
            return <div className="h-4"></div>
          case "spacer-32":
            return <div className="h-8"></div>
          case "hightlight":
            return (
              <span style={{ background: "rgba(68, 215, 182, 0.28)" }}>
                {" "}
                {content}
              </span>
            )
          case "social-links":
            return (
              <div className="social-links my-4 " key={key}>
                <SocialLinks alignment="start" theme="text" />
              </div>
            )
          case "contact-button":
            return (
              <a
                href={`mailto:${email}`}
                target="_blank"
                className="contact-button  primary-button"
              >
                {content} <MailIcons className="ml-3 " />
              </a>
            )

          default:
            break
        }

        if (/\>/.test(content)) {
          let ch = content.split(">")

          return (
            <span className={element.data.label} key={key}>
              {ch.map((c, index) => {
                return (
                  <React.Fragment key={index}>
                    {c}
                    {index !== ch.length - 1 && (
                      <FaArrowRight className="w-4 h-4 inline-block mx-2 relative -top-px" />
                    )}
                  </React.Fragment>
                )
              })}
            </span>
          )
        }

      default:
        return null
    }
  }

function hasChild(children, className) {
  let classes = children.map((item) => {
    return get(item, "props.className", "")
  })

  classes = classes.join("")
  return classes.match("gradient-button")
}
