import { Link, navigate } from "gatsby"
import React from "react"
import CloseIcon from "./CloseIcon"
import { css } from "@emotion/core"
import { motion } from "framer-motion"
export default function CloseButton({ variants, display }) {
  return (
    display && (
      <motion.div
        variants={variants}
        initial={"initialP"}
        animate={"enterP"}
        exit={"exitP"}
        id="close-button"
        onClick={() => {
          if (window.history.state) {
            if (
              (window.__prevLocation &&
                window.__prevLocation.pathname.match(/\/project\//)) ||
              (window.__prevLocation &&
                window.__prevLocation.pathname.match(/my-bookshelf/))
            ) {
              navigate("/story")
            } else {
              window.history.back()
            }
          } else {
            navigate("/")
          }
        }}
        className="h-16 w-16 phone:h-10 phone:w-10 flex items-center justify-center fixed  z-70 phone:cursor-pointer  phone:right-5 phone:top-7 right-8 top-10"
      >
        <div className="  relative">
          <div
            css={css`
              box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.2);
            `}
            className=" flex items-center justify-center cursor-pointer hover:text-primary rounded-full p-3 bg-white  "
          >
            <CloseIcon className="w-5 h-5 phone:w-4 phone:h-4" />
          </div>
        </div>
      </motion.div>
    )
  )
}
