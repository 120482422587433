import { AnimateSharedLayout, motion } from "framer-motion"
import React, { useEffect } from "react"
import cn from "classnames"
import Footer from "./Footer"
import Header from "./Header"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import Seo from "./SEO"
import CloseButton from "./CloseButton"
const duration = 1

const PageTransitionStates = {
  initial: {
    opacity: 0,
  },
  initialP: {
    opacity: 0,
    y: "70vh",
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration,
    },
  },
  enterP: {
    opacity: 1,
    y: 0,
    transition: {
      duration,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,

    transition: {
      delay: 0.15,
      duration: duration * 0.75,
    },
  },

  exitP: {
    opacity: 0,

    transition: {
      delay: 0.15,
      duration: duration * 0.75,
    },
  },
}

export default function PageAnimation({
  children,
  hideHeader,
  location,
  ...rest
}) {
  const { seo } = useStaticQuery(graphql`
    {
      seo: prismicSiteConfigurations {
        data {
          metatitle
          metakeywords
          metadescription
          metaimage {
            url
          }
        }
      }
    }
  `)

  return (
    <>
      <div
        css={css`
          opacity: ${hideHeader ? 0 : 1};
          pointer-events: ${hideHeader ? "none" : "all"};
          transition: 1000ms all ease-in-out;
        `}
      >
        <Header />
      </div>

      <CloseButton variants={PageTransitionStates} display={hideHeader} />

      <motion.div
        id="PageAnimation"
        variants={PageTransitionStates}
        initial={hideHeader ? "initialP" : "initial"}
        animate={hideHeader ? "enterP" : "enter"}
        exit={hideHeader ? "exitP" : "exit"}
        className={cn({
          "fixed w-full max-h-screen overflow-y-scroll top-0 bg-white z-50": hideHeader,
        })}
        {...rest}
      >
        <Seo data={seo.data} />
        {/* {!hideHeader && <Header />} */}
        <div className="overflow-hidden max-w-full">{children}</div>
        <Footer fixedContainer={hideHeader} />
      </motion.div>
      <motion.div
        id="transition-overlay"
        initial={{
          y: "100%",
        }}
        animate={{
          y: "100%",
        }}
        exit={{
          y: "0%",
          transition: {
            duration: 0.75,
          },
        }}
        className="fixed top-0 left-0 w-full h-screen bg-white  z-30  "
      ></motion.div>
    </>
  )
}
