import { Link, navigate } from "gatsby"
import get from "lodash.get"
import React from "react"

export default function LinkResolver({ navlink, link, children, ...rest }) {
  let props = {}
  let Tag = "a"

  switch (link.link_type) {
    case "Web":
      props = {
        href: link.url,
        target: link.target,
      }

      return <Tag {...Object.assign({}, rest, props)}>{children}</Tag>
    case "Asset":
      props = {
        href: link.url,
        target: link.target,
      }

      return <Tag {...Object.assign({}, rest, props)}>{children}</Tag>

    case "Document":
      const page = get(link, "document.type") == "page"
      props = {
        to: page
          ? `/${get(link, "document.uid")}`
          : `/${get(link, "document.type")}/${get(link, "document.uid")}`,
      }

      return (
        <Link
          {...Object.assign({}, rest, props)}
          getProps={({ location }) => {
            const { pathname } = location
            console.log(pathname, props.to)
            return pathname.match(props.to) && pathname !== "/"
              ? { className: rest.className + " active" }
              : {}
          }}
        >
          {children}
        </Link>
      )

    default:
      return <>{children}</>
  }
}
